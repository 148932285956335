import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 338.000000 352.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,352.000000) scale(0.100000,-0.100000)">
          
          <path d="M1563 3153 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M1773 3153 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
<path d="M1483 3143 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M1868 3143 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1428 3133 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1490 3089 c-553 -88 -996 -526 -1086 -1074 -22 -136 -14 -394 15
-512 23 -92 86 -262 103 -278 16 -15 4 30 -30 110 -111 264 -115 625 -9 906
161 427 520 734 960 820 122 24 353 26 477 4 457 -80 845 -415 989 -854 85
-257 92 -482 24 -746 -15 -55 -39 -129 -55 -164 -35 -78 -31 -106 4 -36 99
194 141 535 95 767 -55 275 -172 494 -366 688 -116 115 -218 188 -357 255 -99
48 -259 98 -371 114 -84 13 -313 13 -393 0z"/>
<path d="M1587 2933 c-4 -3 -7 -20 -7 -37 0 -26 4 -31 23 -31 26 1 28 6 21 45
-6 28 -22 38 -37 23z"/>
<path d="M1691 2903 c-1 -38 2 -43 21 -43 19 0 20 1 6 13 -9 6 -19 26 -21 42
-3 24 -4 21 -6 -12z"/>
<path d="M1790 2896 c0 -52 12 -59 34 -18 l15 27 1 -27 c0 -41 25 -35 38 9 13
43 8 55 -13 27 -14 -18 -14 -18 -15 4 0 12 -5 22 -10 22 -6 0 -15 -10 -20 -22
-6 -17 -9 -18 -9 -6 -1 9 -5 20 -11 23 -6 4 -10 -12 -10 -39z"/>
<path d="M1953 2898 c-32 -50 -32 -56 -2 -60 28 -4 29 -3 29 34 0 38 -12 49
-27 26z"/>
<path d="M1343 2884 c-9 -24 10 -68 30 -72 9 -2 17 1 17 7 0 6 -7 11 -15 11
-18 0 -20 20 -2 20 6 0 4 5 -6 11 -14 8 -16 14 -6 25 8 10 9 14 0 14 -6 0 -14
-7 -18 -16z"/>
<path d="M2050 2841 l0 -44 20 26 c21 27 26 42 11 32 -6 -3 -14 3 -20 12 -8
14 -10 9 -11 -26z"/>
<path d="M1241 2842 c-1 -7 6 -26 14 -42 11 -21 20 -27 32 -23 15 6 14 8 -3
16 -12 5 -25 21 -31 35 -6 15 -11 21 -12 14z"/>
<path d="M2154 2826 c-3 -9 -1 -23 6 -31 17 -20 5 -32 -15 -15 -18 15 -20 -1
-3 -18 31 -31 63 5 33 38 -16 17 -16 20 -2 20 8 0 18 -6 21 -12 3 -7 5 -4 3 7
-3 24 -35 32 -43 11z"/>
<path d="M1136 2801 c-8 -12 26 -71 40 -71 6 0 17 6 23 14 10 12 9 20 -4 40
-17 26 -49 35 -59 17z m34 -11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z m20 -36 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0
5 5 9 10 9 6 0 10 -7 10 -16z"/>
<path d="M1050 2736 c0 -8 4 -26 10 -41 l10 -25 21 21 22 22 -24 18 c-29 22
-39 24 -39 5z"/>
<path d="M2300 2720 c-32 -26 -33 -29 -13 -30 12 0 23 -8 26 -17 3 -13 9 -7
21 21 23 52 8 63 -34 26z"/>
<path d="M1560 2709 c-99 -15 -251 -71 -342 -125 -73 -44 -202 -158 -253 -224
-100 -128 -162 -283 -186 -462 -23 -175 14 -362 104 -531 46 -86 58 -96 100
-88 l28 6 -27 35 c-37 49 -98 183 -119 260 -10 37 -19 120 -22 190 -4 104 -2
140 16 214 63 264 220 462 459 581 105 52 201 76 328 82 238 12 448 -62 617
-217 116 -107 180 -202 232 -348 33 -94 45 -169 45 -288 0 -164 -45 -324 -126
-451 -19 -30 -34 -57 -32 -58 2 -2 16 -5 32 -7 25 -2 34 4 57 37 36 51 91 180
115 270 28 107 26 333 -4 438 -81 283 -260 495 -516 612 -146 67 -353 97 -506
74z"/>
<path d="M970 2695 c1 -5 12 -23 25 -40 14 -16 25 -25 25 -20 -1 6 -12 24 -25
40 -14 17 -25 26 -25 20z"/>
<path d="M2410 2676 c0 -7 -7 -19 -15 -26 -29 -24 -15 -31 14 -8 34 26 37 34
16 42 -9 3 -15 0 -15 -8z"/>
<path d="M900 2613 c0 -5 11 -20 25 -33 25 -23 26 -23 43 -6 16 18 16 19 -2
12 -13 -4 -27 1 -43 15 -12 13 -23 18 -23 12z"/>
<path d="M827 2563 c-13 -13 -7 -35 16 -54 27 -23 41 -24 58 -3 12 15 11 16
-9 10 -16 -5 -22 -3 -22 8 0 10 -6 13 -17 9 -13 -5 -15 -2 -11 15 6 21 -2 29
-15 15z"/>
<path d="M2508 2535 c-19 -37 -18 -40 10 -26 9 5 26 13 37 17 18 6 18 8 3 11
-10 3 -18 11 -18 19 0 24 -14 15 -32 -21z"/>
<path d="M1646 2513 c-19 -21 -51 -60 -71 -86 -25 -33 -43 -47 -54 -44 -9 3
-58 22 -109 41 -51 20 -97 34 -101 32 -7 -5 -23 -86 -36 -187 l-6 -45 -114
-18 c-65 -11 -116 -23 -118 -30 -3 -7 12 -59 34 -116 21 -57 39 -104 39 -106
0 -1 -38 -32 -84 -69 -46 -36 -87 -72 -89 -80 -6 -14 14 -33 111 -109 34 -27
62 -51 62 -54 0 -3 -16 -45 -35 -93 -19 -49 -35 -100 -35 -113 0 -28 -2 -27
145 -49 l79 -12 9 -37 c18 -75 27 -35 27 119 0 148 14 283 29 283 4 0 13 -35
20 -77 22 -131 115 -353 147 -353 9 0 14 3 12 8 -2 4 -16 32 -32 62 -37 71
-78 181 -71 189 4 3 16 -8 28 -24 70 -97 239 -239 278 -233 18 3 14 8 -32 40
-63 43 -112 88 -106 95 3 2 41 -7 85 -21 93 -29 262 -56 356 -56 l66 0 0 -36
c0 -46 23 -41 30 7 l5 34 80 11 c44 7 96 16 115 21 31 9 35 13 32 39 -2 16
-15 56 -29 89 -44 103 -42 114 21 159 30 21 69 54 88 73 l33 34 -40 36 c-22
20 -64 53 -93 75 -58 43 -58 46 -22 118 23 45 51 140 45 150 -3 4 -56 16 -119
25 l-113 17 -7 67 c-8 74 -25 152 -36 164 -4 5 -56 -10 -115 -33 -89 -35 -109
-40 -119 -29 -7 8 -38 47 -70 87 -32 39 -64 72 -72 72 -7 0 -29 -17 -48 -37z
m105 -61 c95 -118 156 -223 209 -355 12 -32 20 -61 16 -64 -4 -4 -21 11 -38
33 -79 98 -209 205 -337 276 -17 9 -31 21 -31 25 0 15 111 143 123 143 7 0 33
-26 58 -58z m-334 -57 c101 -39 242 -116 327 -180 102 -76 103 -91 6 -54 -101
37 -220 59 -332 59 -61 0 -110 4 -113 10 -6 9 10 147 20 178 6 17 15 16 92
-13z m651 -40 c20 -87 19 -407 -1 -460 -17 -46 -19 -43 -41 72 -18 92 -60 201
-117 305 -23 41 -36 75 -31 80 15 15 146 67 162 65 10 -1 20 -24 28 -62z
m-470 -180 c17 -4 32 -10 32 -14 0 -4 -40 -14 -88 -23 -107 -21 -224 -64 -316
-118 -37 -22 -74 -40 -81 -40 -6 0 -23 30 -38 68 -44 117 -45 109 17 122 123
25 372 28 474 5z m635 1 c37 -7 70 -16 74 -20 16 -16 -92 -255 -162 -359 -47
-70 -102 -133 -109 -126 -3 3 5 36 18 73 38 105 56 195 63 326 7 138 -2 129
116 106z m-425 -69 c125 -52 202 -161 210 -297 4 -72 2 -79 -38 -157 -41 -83
-55 -97 -145 -149 -45 -26 -59 -29 -141 -29 -76 0 -98 4 -137 24 -73 37 -120
84 -157 152 -30 58 -33 71 -33 150 0 84 1 90 40 156 49 81 60 92 137 132 52
28 67 31 146 31 48 0 101 -6 118 -13z m-388 -65 c-101 -84 -230 -242 -271
-334 -7 -16 -17 -28 -23 -28 -18 0 -146 101 -146 115 0 16 82 86 168 144 82
55 260 141 292 141 23 0 22 -3 -20 -38z m-94 -193 c-38 -113 -56 -218 -56
-329 0 -84 -3 -102 -16 -107 -21 -8 -165 14 -178 27 -24 24 119 313 212 430
65 81 72 77 38 -21z m1014 13 c32 -26 61 -52 64 -59 5 -12 -93 -95 -184 -155
-90 -58 -258 -138 -293 -138 -6 0 25 34 70 75 86 80 169 183 223 278 23 41 37
56 47 52 8 -3 41 -27 73 -53z m-58 -325 c17 -43 28 -84 25 -91 -13 -32 -272
-54 -432 -37 -139 15 -157 25 -79 41 156 31 299 83 385 140 24 16 50 28 58 27
7 -1 27 -37 43 -80z"/>
<path d="M755 2460 c-5 -15 0 -26 17 -40 29 -24 57 -28 38 -5 -10 12 -9 15 4
15 15 0 15 2 0 25 -20 30 -50 33 -59 5z m35 -10 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M2567 2460 c-33 -33 1 -79 38 -52 28 20 37 42 25 57 -17 20 -41 18
-63 -5z m53 -4 c0 -18 -15 -36 -31 -36 -18 0 -26 20 -12 33 7 8 43 10 43 3z"/>
<path d="M2631 2361 c-17 -13 -22 -23 -16 -39 7 -25 22 -27 60 -10 28 12 35
34 8 23 -59 -24 -72 -11 -25 24 17 13 21 20 12 20 -8 1 -26 -8 -39 -18z"/>
<path d="M674 2322 c-26 -18 -23 -42 7 -58 38 -19 59 -12 59 19 0 16 -9 28
-25 35 -30 14 -27 14 -41 4z m46 -27 c7 -9 10 -18 6 -22 -8 -9 -56 14 -56 27
0 15 36 12 50 -5z"/>
<path d="M2690 2260 c-23 -13 -29 -19 -17 -20 13 0 17 -5 12 -19 -5 -16 -2
-18 27 -12 23 5 34 13 36 27 5 35 -18 45 -58 24z m40 -20 c0 -5 -2 -10 -4 -10
-3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M643 2206 c16 -13 16 -14 -8 -20 -34 -9 -32 -22 6 -35 39 -13 39 -13
39 -2 0 5 -8 11 -17 14 -14 3 -11 6 10 11 34 10 34 19 2 34 -35 16 -54 15 -32
-2z"/>
<path d="M3001 2155 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M2759 2091 c-1 -6 -2 -15 -3 -21 0 -5 -5 -10 -11 -9 -5 0 -9 7 -7 15
2 8 -2 14 -8 14 -14 0 -13 -47 3 -52 22 -9 79 21 75 40 -4 19 -47 30 -49 13z"/>
<path d="M580 2077 c0 -7 16 -21 36 -30 25 -13 35 -14 31 -5 -2 7 2 21 11 31
14 16 12 17 -31 17 -32 0 -47 -4 -47 -13z"/>
<path d="M3021 2074 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M351 2044 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3032 2020 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M342 2000 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2753 1983 c-15 -6 -17 -43 -3 -43 6 0 10 8 10 18 1 15 2 15 15 -2
14 -18 15 -18 21 -1 7 17 8 17 21 -1 12 -16 13 -16 13 9 0 24 -4 27 -32 26
-18 0 -39 -3 -45 -6z"/>
<path d="M3043 1930 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M334 1795 c0 -82 2 -115 3 -72 2 43 2 110 0 150 -1 40 -3 5 -3 -78z"/>
<path d="M557 1923 c-4 -3 -7 -17 -7 -30 0 -21 4 -23 43 -22 23 0 36 3 29 6
-7 3 -17 16 -22 29 -9 23 -29 31 -43 17z m28 -23 c3 -5 -1 -10 -10 -10 -9 0
-13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z"/>
<path d="M2750 1870 c0 -5 8 -10 18 -10 16 -1 16 -1 -1 -14 -15 -12 -14 -14
19 -19 45 -8 54 -3 54 29 0 22 -4 24 -45 24 -25 0 -45 -4 -45 -10z m70 -20 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M3053 1795 c0 -38 2 -53 4 -32 2 20 2 52 0 70 -2 17 -4 1 -4 -38z"/>
<path d="M583 1809 c-57 -17 -52 -35 13 -49 31 -7 46 1 25 14 -5 3 -7 15 -4
26 3 11 4 20 2 19 -2 0 -18 -5 -36 -10z"/>
<path d="M2790 1761 c-45 -8 -46 -32 -1 -43 39 -11 54 -2 20 11 -24 9 -21 12
21 23 26 8 -5 14 -40 9z"/>
<path d="M620 1685 c0 -8 -4 -15 -10 -15 -5 0 -10 5 -10 10 0 18 -20 11 -20
-7 -1 -16 -2 -16 -15 1 -15 19 -15 19 -15 -2 0 -19 5 -22 45 -22 41 0 45 2 45
25 0 14 -4 25 -10 25 -5 0 -10 -7 -10 -15z"/>
<path d="M3043 1665 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M2750 1652 c0 -13 62 -22 75 -11 5 6 1 9 -10 9 -11 0 -30 3 -42 6
-13 4 -23 2 -23 -4z"/>
<path d="M342 1600 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M600 1593 c-34 -6 -50 -23 -22 -23 13 0 22 -6 22 -15 0 -8 -4 -15
-10 -15 -5 0 -10 -4 -10 -9 0 -4 18 -3 39 2 22 6 38 14 35 18 -3 5 -11 6 -19
2 -9 -3 -15 1 -15 11 0 9 5 16 10 16 6 0 10 5 10 10 0 6 -3 9 -7 9 -5 -1 -19
-4 -33 -6z"/>
<path d="M3032 1575 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2730 1575 c-7 -9 -9 -22 -4 -32 7 -16 8 -15 11 5 3 15 11 22 28 22
29 0 41 -16 25 -35 -10 -12 -9 -15 3 -15 18 0 29 36 13 48 -23 19 -63 22 -76
7z"/>
<path d="M351 1544 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3021 1524 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M650 1480 c0 -5 5 -10 10 -10 18 0 10 -29 -10 -35 -25 -8 -41 1 -33
20 3 8 0 15 -6 15 -19 0 -13 -47 7 -52 44 -12 86 28 62 57 -14 17 -30 20 -30
5z"/>
<path d="M2697 1460 c-3 -11 -1 -20 4 -20 5 0 9 5 9 10 0 6 5 10 10 10 6 0 10
-7 10 -16 0 -13 3 -14 14 -5 11 10 16 9 24 -5 8 -15 9 -15 15 0 5 13 -2 21
-28 32 -45 18 -52 18 -58 -6z"/>
<path d="M3002 1440 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M391 1384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2976 1365 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M1340 1273 c-25 -1 -115 -7 -201 -13 -298 -20 -776 -83 -799 -106
-11 -11 44 -310 61 -330 12 -15 132 -74 150 -74 5 0 9 22 9 50 0 48 1 49 32
54 18 3 62 10 98 15 139 22 466 51 695 62 406 19 900 -4 1315 -62 129 -18 120
-12 120 -74 0 -30 3 -55 6 -55 19 0 164 85 168 98 11 38 57 315 52 319 -7 8
-284 50 -466 72 -292 34 -602 51 -909 49 -157 -1 -306 -3 -331 -5z m652 -75
c64 -44 53 -168 -19 -206 -54 -29 -142 -3 -162 47 -36 87 -2 167 75 180 47 8
71 4 106 -21z m-621 -13 c1 -19 2 -41 3 -47 1 -8 21 -14 54 -16 l52 -3 0 50
c0 48 2 51 25 51 25 0 25 -1 27 -90 1 -49 2 -100 2 -112 1 -16 -6 -24 -21 -26
-19 -3 -22 2 -25 40 l-3 43 -50 0 -50 0 -3 -48 c-3 -43 -5 -48 -25 -45 -21 3
-22 10 -29 113 -4 60 -4 113 -2 117 3 4 14 8 25 8 15 0 19 -7 20 -35z m345 13
c3 -13 24 -62 45 -109 22 -48 39 -89 39 -93 0 -16 -50 -4 -60 14 -9 16 -21 20
-65 20 -44 0 -56 -4 -65 -20 -10 -18 -60 -30 -60 -14 0 3 21 55 47 115 46 106
48 109 79 109 25 0 34 -5 40 -22z m-443 -30 c20 -24 27 -43 27 -75 0 -86 -43
-123 -143 -123 l-54 0 -6 68 c-4 37 -7 88 -7 114 0 45 1 47 33 51 72 10 121
-1 150 -35z m888 -23 c24 -35 47 -61 51 -57 4 4 8 31 10 61 3 55 12 68 36 53
10 -7 11 -33 5 -118 -10 -138 -21 -143 -88 -43 -53 80 -65 78 -65 -8 0 -51 -1
-53 -26 -53 l-27 0 7 77 c3 42 6 93 6 115 0 33 3 38 24 38 18 0 34 -15 67 -65z
m-1117 47 c13 -21 18 -163 6 -191 -15 -37 -58 -55 -112 -48 -66 9 -85 47 -91
177 -2 39 1 45 20 48 19 3 22 -2 28 -65 9 -92 13 -103 42 -114 19 -7 32 -6 51
7 24 16 24 18 18 98 -4 45 -4 85 0 89 10 9 32 9 38 -1z m1308 -3 c2 -6 0 -48
-4 -92 -4 -45 -5 -84 0 -88 4 -5 29 -9 56 -11 49 -3 62 -13 48 -34 -5 -8 -33
-10 -85 -6 l-79 5 7 91 c9 127 13 146 35 146 10 0 20 -5 22 -11z m198 -19 c0
-38 -23 -217 -29 -223 -4 -4 -16 -5 -25 -1 -16 6 -17 15 -12 68 18 171 16 166
42 166 13 0 24 -4 24 -10z m122 -69 c23 -38 46 -67 50 -65 5 3 8 20 8 37 0 51
11 77 31 77 10 0 20 -3 22 -7 5 -8 -19 -195 -28 -220 -9 -25 -36 1 -77 73 -44
78 -58 81 -58 11 0 -52 -14 -77 -40 -70 -17 4 -20 11 -16 32 3 14 10 61 16
105 5 43 13 82 16 87 13 22 36 4 76 -60z m-1870 -41 c32 -52 58 -100 58 -107
0 -20 -44 -16 -57 5 -17 28 -104 15 -137 -21 -35 -38 -90 -37 -140 2 -43 34
-68 31 -66 -8 3 -44 1 -51 -20 -51 -21 0 -21 0 -44 148 -7 46 -10 85 -6 87 4
2 36 7 72 11 64 6 102 -3 120 -30 13 -19 9 -74 -7 -90 -8 -8 -15 -18 -15 -23
0 -11 37 -43 50 -43 5 0 23 46 41 103 17 56 33 106 36 110 3 5 17 7 31 5 21
-2 37 -22 84 -98z m2123 67 c59 -11 70 -16 70 -32 0 -18 -7 -20 -65 -20 -51 0
-65 -3 -68 -16 -2 -12 11 -19 58 -29 49 -10 60 -16 60 -33 0 -19 -5 -19 -66
-14 -63 6 -65 6 -62 -16 2 -18 12 -23 56 -30 53 -9 75 -28 55 -48 -10 -10
-151 8 -166 21 -7 6 15 179 28 218 5 14 10 14 100 -1z"/>
<path d="M1882 1173 c-7 -3 -19 -18 -28 -35 -28 -54 2 -108 59 -108 38 0 50 7
66 38 33 62 -32 132 -97 105z"/>
<path d="M1656 1122 c-18 -34 -13 -42 25 -42 27 0 27 1 17 30 -14 42 -25 45
-42 12z"/>
<path d="M1144 1146 c-3 -7 -4 -38 -2 -67 3 -51 5 -54 31 -57 46 -5 77 22 77
67 0 24 -7 44 -18 54 -22 20 -81 22 -88 3z"/>
<path d="M706 1068 c-12 -48 -8 -59 22 -52 37 9 36 8 16 43 -22 37 -30 39 -38
9z"/>
<path d="M446 1071 c-4 -6 -4 -22 0 -36 6 -25 10 -26 43 -20 43 8 54 17 49 41
-2 14 -14 20 -45 22 -22 2 -44 -1 -47 -7z"/>
<path d="M1515 897 c13 -8 71 -12 175 -12 104 0 162 4 175 12 16 10 -17 12
-175 12 -158 0 -191 -2 -175 -12z"/>
<path d="M800 851 c0 -17 149 -131 235 -180 173 -99 336 -151 539 -171 292
-29 597 49 846 215 76 51 170 134 153 135 -6 0 -33 -18 -58 -40 -199 -172
-472 -279 -755 -296 -302 -18 -634 87 -864 273 -87 71 -96 77 -96 64z"/>
<path d="M1958 463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1468 453 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1898 453 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1528 443 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1808 443 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
